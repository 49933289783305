.alertDiv {
  position: absolute !important;
  width: 100%;
  text-align: center;
  opacity: 1 !important;
  padding: 15px 0 !important;
  font-size: 20px;
  z-index: 2;
  top: 0;
  left: 0;
}

.alert-success {
  background-color: #408bf9 !important;
  color: #ffffff !important;
  border: none !important;
  border-radius: 0 !important;
}

.alert-danger {
  background-color: #cb2026 !important;
  color: #ffffff !important;
  border: none !important;
  border-radius: 0 !important;
}

@media (max-width: 768px) {
  .alertDiv {
    /* position: relative !important; */
    text-align: left;
    padding: 15px !important;
    font-size: 18px;
    margin-bottom: 0 !important;
  }
}

.alert-dismissible .close {
  z-index: 2 !important;
  position: absolute;
  top: 0 !important;
  right: 30px !important;
  padding: 0.75rem 1.25rem;
  color: inherit;
  font-size: 45px;
  font-weight: 700 !important;
}

@media (max-width: 768px) {
  .alert-dismissible .close {
    /* position: relative !important; */
    top: 40px !important;
    padding: 0 !important;
    font-size: 32px;
  }
}

.alertText {
  width: 60%;
  margin: auto;
}
