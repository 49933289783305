.hideDiv {
  animation: FadeAnimation 5s ease-in 0.2s forwards;
}

@keyframes FadeAnimation {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

/* Main Form */

.formMain {
  padding: 60px 30px;
}

.logoutLink {
  text-decoration: none !important;
  color: #0050f0;
  font-size: 18px;
}

.header {
  text-align: center;
  padding: 5px 0 10px 0;
}

.logo {
  width: 300px;
  height: auto;
  margin: auto;
  display: block;
  margin-bottom: 30px;
}

.formDiv {
  max-width: 800px;
  margin: auto;
}

.required {
  color: red;
}

.dzu-dropzone {
  height: 200px;
}

.dzu-dropzone::-webkit-scrollbar {
  display: none;
}

.dzu-inputLabelWithFiles,
.dzu-inputLabel {
  color: #0051f3;
}

input {
  border: 1px solid #cdcdcd;
  font-size: 1rem;
  padding: 0.5rem;
}

input:focus {
  outline: 2px solid #1695a3;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  position: relative;
}

.formRow > * {
  margin: 15px 0;
}

/* Form Inputs */

.formInputs {
  width: 100%;
  padding: 15px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.formInputs::-webkit-outer-spin-button,
.formInputs::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

.formInputsTextArea {
  width: 100%;
  padding: 15px;
}

/* .formInputs::placeholder {
} */

/* Consent Form */

.consentFormDiv {
  width: 100%;
  height: 500px;
  overflow: scroll;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 30px;
}

@media (max-width: 768px) {
  .consentFormDiv {
    padding: 15px;
  }
}

/* width */
.consentFormDiv::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.consentFormDiv::-webkit-scrollbar-track {
  border-radius: 50px;
}

/* Handle */
.consentFormDiv::-webkit-scrollbar-thumb {
  background: #c9c9c9;
  border-radius: 50px;
}

/* Handle on hover */
.consentFormDiv::-webkit-scrollbar-thumb:hover {
  background: #ebebeb;
}

.consentCheckboxDiv {
  padding: 25px 0 30px 0;
  display: flex;
}

.consentCheckbox .form-check-label {
  font-weight: bold;
}

.consentCheckboxLabel {
  font-weight: bold;
  padding: 0 10px;
}

.sportsDiv {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -15px;
}

.sportsCheckboxDiv {
  padding-right: 20px;
}

.sportsLabel {
  padding: 0 5px;
}

.dropzoneText {
  font-size: 18px;
}

.submitButton {
  background-color: #0051f3 !important;
  border: 1px solid #0051f3 !important;
  border-radius: 0.25rem;
}

.submitButton:hover {
  background-color: #ffffff !important;
  color: #0051f3 !important;
}

.fileButtonDiv {
  display: flex;
}

.fileButtonName {
  margin: auto 0;
  color: #0051f3;
  font-size: 20px;
  font-weight: bold;
}

.fileCloseButton {
  background: none !important;
  border: none !important;
  color: #000000 !important;
  padding: 0 !important;
  margin: auto 0 auto 30px;
  font-size: 24px !important;
  font-weight: bold !important;
}

.fileCloseButton:hover {
  color: #0051f3 !important;
}
