/* Main */

#fullHeight {
  height: 100vh;
  display: flex;
}
#loading {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(0, 80, 240, 0.3);
  border-radius: 50%;
  border-top-color: #0050f0;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  margin: auto;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.overflowXHidden {
  overflow-x: hidden !important;
}

.absolute {
  position: absolute;
}

.flex {
  display: flex;
}

.displayTable {
  display: table;
}

.displayTableCell {
  display: table-cell;
  vertical-align: middle;
}

.displayNone {
  display: none;
  transition: visibility 0s, opacity 0.5s linear;
}

.displayBlock {
  display: block;
}

.blur {
  text-shadow: 0 0 10px #000000;
  color: transparent;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
}

.hoverDelay {
  transition-duration: 0.4s;
}

.bold {
  font-weight: 700 !important;
}

.heavyBold {
  font-weight: 900;
}

@font-face {
  font-family: "Avenir Next";
  src: url("../font/Avenir-Next-LT-Pro-Bold-Condensed-Italic_5180.ttf");
}

.avenirBoldItalic {
  font-family: "Avenir Next";
  font-style: italic;
  font-weight: 900;
}

@font-face {
  font-family: "Avenir Next";
  src: url("../font/Avenir-Next-LT-Pro-Bold_5182.ttf");
}

.avenirBold {
  font-family: "Avenir Next" !important;
  font-style: normal;
  /* font-weight: 900; */
}

@font-face {
  font-family: "Avenir Next";
  src: url("../font/Avenir_Next_Condensed.ttc");
}

.avenir {
  font-family: "Avenir Next" !important;
  font-style: normal;
  font-weight: 400;
}

.arial {
  font-family: "Arial" !important;
  font-style: normal;
  font-weight: 400;
}

.errorText {
  color: red;
  text-align: center;
  font-size: 16px;
}

/* Color */

.blue {
  color: #0051f3;
}

.grey {
  color: #b1b1b1;
}

.opaque {
  opacity: 0 !important;
}

/* Global Styles */

.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths,
.col-xl-5ths {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.col-xs-5ths {
  width: 20%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-5ths {
    width: 20%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-5ths {
    width: 20%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-5ths {
    width: 20%;
    float: left;
  }
}

@media (min-width: 1650px) {
  .col-xl-5ths {
    width: 20%;
    float: left;
  }
}

.boxShadow {
  -webkit-box-shadow: 0px 0px 77px -17px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 77px -17px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 77px -17px rgba(0, 0, 0, 0.75);
}
