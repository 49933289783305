.mainModal {
  padding: 15px 30px !important;
}

.modal-content {
  padding: 0 0 10px 0;
}

.modalHeader {
  font-size: 32px !important;
}

.modalInputCol {
  margin: 5px 0 !important;
}

.registerToggleLink {
  text-decoration: none !important;
  color: #0050f0;
}

.registerButton {
  width: 100%;
  padding: 10px 0 !important;
  background-color: #0050f0 !important;
  color: #ffffff;
  border: 1px solid #0050f0 !important;
  border-radius: 2.5px !important;
}

.registerButton:hover {
  background-color: transparent !important;
  color: #0050f0;
}

@media (min-width: 768px) {
  .modalForgotPwdCol {
    text-align: right;
  }
}

.registerButton:disabled {
  cursor: not-allowed !important;
}
